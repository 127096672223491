import axios from '../../utils/axios';


const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
    isEditRoleLoading: false,
    editRole: false,
    editRoleDataFetchCompleted: false,
};

const editRoleDataSlice = createSlice({
    name: 'editRoleData',
    initialState,
    reducers: {
        startLoading (state, action) {
            state.isEditRoleLoading = true;
            state.editRole = false;
        },
        insertRoleDataToEdit (state, action) {

            state.isEditRoleLoading = false;
            state.editRoleDataFetchCompleted = true;
            state.editRole = true;
        },
        resetStatus (state, action) {
            state.isEditRoleLoading = false;
            state.editRoleDataFetchCompleted = false;
            state.editRole = false;
        },

    },
});

export default editRoleDataSlice.reducer;

export function editDataToBeEdited ({ roleName, permission }) {
    return async (dispatch) => {
        dispatch(editRoleDataSlice.actions.startLoading());
        try
        {
            const response = await axios.request({
                url: `roles/${ roleName }/permission`,
                data: { permission },
                method: "POST"
            })
            dispatch(editRoleDataSlice.actions.insertRoleDataToEdit());
        } catch (error)
        {
            console.error(error);
        }
    }

}
export function resetRoleDataEditStatus () {
    return async (dispatch) => {
        dispatch(editRoleDataSlice.actions.resetStatus());
    }
}

