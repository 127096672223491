import axios from '../../utils/axios';


const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
    isDeleteLoading: false,
    deleteData: false,
    deleteDataFetchCompleted: false,
};

const deleteDataSlice = createSlice({
    name: 'deleteDataRows',
    initialState,
    reducers: {
        startLoading (state, action) {
            state.isDeleteDataLoading = true;
            state.deleteData = false;
        },
        insertDeleteData (state, action) {

            state.isDeleteDataLoading = false;
            state.deleteDataFetchCompleted = true;
            state.deleteData = true;
        },
        resetStatus (state, action) {
            state.isDeleteDataLoading = false;
            state.deleteDataFetchCompleted = false;
            state.deleteData = false;
        },

    },
});

export default deleteDataSlice.reducer;

export function getDeleteData (username) {
    return async (dispatch) => {
        dispatch(deleteDataSlice.actions.startLoading());
        try
        {

            const response = await axios.delete(`admin/user/${ username }`);
            dispatch(deleteDataSlice.actions.insertDeleteData());
        } catch (error)
        {
            console.error(error);
        }
    }

}
export function resetDeleteDataStatus () {
    return async (dispatch) => {
        dispatch(deleteDataSlice.actions.resetStatus());
    }
}

