import axios from '../../utils/axios';


const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
    isChartLoading: false,
    chart: [],
    chartFetchCompleted: false,
};

const chartSlice = createSlice({
    name: 'chartdata',
    initialState,
    reducers: {
        startLoading (state, action) {
            state.isChartLoading = true;
            state.chart = [];
        },
        insertChart (state, action) {

            state.isChartLoading = false;
            state.chartFetchCompleted = true;
            state.chart = action.payload;
        },
        updateChart (state, action) {
            state.isChartLoading = false;
            state.chartFetchCompleted = true;
        },
    },
});

export default chartSlice.reducer;

export function getChart () {
    return async (dispatch) => {

        dispatch(chartSlice.actions.startLoading());
        try
        {
            const response = await axios.get(`gettotaljobs?week=4`);
            dispatch(chartSlice.actions.insertChart(response.data));
        } catch (error)
        {
            console.error(error);
        }
    }

}

