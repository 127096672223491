import axios from '../../utils/axios';


const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
    isCreateUserLoading: false,
    userCreated:false,
    newUserDataFetchCompleted: false,
    hasError: false,
    message:""
};

const editUserSlice = createSlice({
    name: 'editUser',
    initialState,
    reducers: {
        startLoading (state, action) {
            state.isCreateUserLoading = true;
            state.userCreated = false;
            state.hasError = false;
            state.message = "";
        },
        insertCreateUser (state, action) {

            state.isCreateUserLoading = false;
            state.newUserDataFetchCompleted = true;
            state.userCreated = true;
            state.hasError = false;
            state.message = "";
        },
        resetUser (state, action) {
            return initialState;
        },
        insertEditUserError (state, action) {
            state.isCreateUserLoading = false;
            state.newUserDataFetchCompleted = true;
            state.userCreated = false;
            state.hasError = true;
            state.message = action.payload;
        }
    },
});

export default editUserSlice.reducer;

export function editUserAction ({ username, data }) {
    console.log(data);
    return async (dispatch) => {

        dispatch(editUserSlice.actions.startLoading());
        try
        {
            const response = await axios.request({ url: `admin/user/${username}`,method:"PATCH", data: {roleName:data.roles,disableUser:data.Enabled,name:data.name } });
            dispatch(editUserSlice.actions.insertCreateUser());
        } catch (error)
        {
            
             dispatch(editUserSlice.actions.insertEditUserError(error.Error));
        }
    }

}

export function resetEditUserAction () {
    return async (dispatch) => {
        dispatch(editUserSlice.actions.resetUser());  
    }
}

