import axios from '../../utils/axios';


const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
    isDisableUserLoading: false,
    disableUserFetchCompleted: false,
    isUserDisabled: false
};

const disableUserSlice = createSlice({
    name: 'disableUserStatus',
    initialState,
    reducers: {
        startLoading (state, action) {
            state.isDisableUserLoading = true;
            state.disableUserFetchCompleted = false;
            state.isUserDisabled = false;
        },
        insertDisableUser (state, action) {

            state.isDisableUserLoading = false;
            state.disableUserFetchCompleted = true;
            state.isUserDisabled = true;
        },
        userDisableStatusFailed (state, action) {
            state.isDisableUserLoading = false;
            state.disableUserFetchCompleted = true;
            state.isUserDisabled = false;
        },
        resetStatus (state, action) {
            state.isDisableUserLoading = false;
            state.disableUserFetchCompleted = false;
            state.isUserdisables = false;
        },
    },
});

export default disableUserSlice.reducer;

export function disableUserStatusAction (username) {
    return async (dispatch) => {

        dispatch(disableUserSlice.actions.startLoading());
        try
        {
            const response = await axios.patch(`admin/user/${ username }/disable`);
            dispatch(disableUserSlice.actions.insertDisableUser(response.data));
        } catch (error)
        {
            dispatch(disableUserSlice.actions.userDisableStatusFailed());
        }
    }

}

export function resetUserDisableStatus () {
    return async (dispatch) => {
        dispatch(disableUserSlice.actions.resetStatus());
    }
}
