import { isArray } from 'lodash';
import axios from '../../utils/axios';


const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
    isDeleteRoleDataLoading: false,
    deleteRoleData: false,
    deleteRoleDataFetchCompleted: false,
};

const deleteRoleDataSlice = createSlice({
    name: 'deleteRoleData',
    initialState,
    reducers: {
        startLoading (state, action) {
            state.isDeleteRoleLoading = true;
            state.deleteRoleData = false;
        },
        insertRoleDataToDelete (state, action) {

            state.isDeleteRoleLoading = false;
            state.deleteRoleDataFetchCompleted = true;
            state.deleteRoleData = true;
        },
        resetStatus (state, action) {
            state.isDeleteRoleLoading = false;
            state.deleteRoleDataFetchCompleted = false;
            state.deleteRoleData = false;
        },

    },
});

export default deleteRoleDataSlice.reducer;

export function RoleDataToBeDeleted (roleName) {
    return async (dispatch) => {
        dispatch(deleteRoleDataSlice.actions.startLoading());
        try
        {

            const response = await axios.delete(`roles`, { data: { roleName: isArray(roleName) ? roleName : [ roleName ] } });


            dispatch(deleteRoleDataSlice.actions.insertRoleDataToDelete());
        } catch (error)
        {
            console.error(error);
        }
    }

}
export function resetRoleDataDeleteStatus () {
    return async (dispatch) => {
        dispatch(deleteRoleDataSlice.actions.resetStatus());
    }
}

