// ----------------------------------------------------------------------

function path (root, sublink) {
  return `${ root }${ sublink }`;
}

const ROOTS_AUTH = '/';
const ROOTS_DASHBOARD = '/';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: '/auth/login',
  register: '/auth/register',
  loginUnprotected: '/auth/login-unprotected',
  registerUnprotected: '/auth/register-unprotected',
  verify: '/auth/verify',
  resetPassword: '/auth/reset-password',
  newPassword: '/auth/new-password',
};

export const PATH_PAGE = {
  maintenance: '/maintenance',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  blank: path(ROOTS_DASHBOARD, '/blank'),
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    companies: path(ROOTS_DASHBOARD, '/companies'),
    company: (company_name) => path(ROOTS_DASHBOARD, `/company/${ company_name }`),

  },

  user: {
    root: '/user',
    new: '/user/new',
    list: '/user/list',
    cards: '/user/cards',
    profile: '/user/profile',
    account: '/user/account',
    edit: (name) => `/user/${ name }/edit`,
    demoEdit: `/user/reece-chung/edit`,
  },
  role: {
    root: '/role',
    list: '/role/list',
    new: '/role/new',
    edit: (roleName) => `/role/${ roleName }/edit`,
  }
};

export const PATH_DOCS = {
  // root: 'mailto:g4eng-dev-aaaaf6xhsfxwp3cerc2wa5j2bq@spectacorgaming.slack.com',
  changelog: 'https://docs.minimals.cc/changelog',
};

export const PATH_ZONE_ON_STORE = 'https://mui.com/store/items/zone-landing-page/';

export const PATH_MINIMAL_ON_STORE = 'https://mui.com/store/items/minimal-dashboard/';

export const PATH_FREE_VERSION = 'https://mui.com/store/items/minimal-dashboard-free/';

export const PATH_FIGMA_PREVIEW =
  'https://www.figma.com/file/rWMDOkMZYw2VpTdNuBBCvN/%5BPreview%5D-Minimal-Web.26.11.22?node-id=0%3A1&t=ya2mDFiuhTXXLLF1-1';
