import axios from '../../utils/axios';


const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
    isCreatingRoleLoading: false,
    createRoleData: false,
    roleDataFetchCompleted: false,
};

const createRoleDataSlice = createSlice({
    name: 'createRole',
    initialState,
    reducers: {
        startLoading (state, action) {
            state.isCreatingRoleLoading = true;
            state.createRoleData = false;
        },
        insertRoleData (state, action) {

            state.isCreatingRoleLoading = false;
            state.roleDataFetchCompleted = true;
            state.createRoleData = true;
        },
        resetStatus (state, action) {
            state.isCreatingRoleLoading = false;
            state.roleDataFetchCompleted = false;
            state.createRoleData = false;
        },

    },
});

export default createRoleDataSlice.reducer;

export function createRoleDataStatus (roleName) {
    return async (dispatch) => {
        dispatch(createRoleDataSlice.actions.startLoading());
        try
        {

            const response = await axios.request({
                url: 'roles',
                method: 'POST',
                data: { roleName }
            })
            dispatch(createRoleDataSlice.actions.insertRoleData());
        } catch (error)
        {
            console.error(error);
        }
    }

}
export function resetRoleDataStatus () {
    return async (dispatch) => {
        dispatch(createRoleDataSlice.actions.resetStatus());
    }
}

