import axios from '../../utils/axios';


const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
    isCreatePermissionLoading: false,
    createPermissionData: false,
    permissionDataFetchCompleted: false,
};

const createPermissionDataSlice = createSlice({
    name: 'createPermission',
    initialState,
    reducers: {
        startLoading (state, action) {
            state.isCreatePermissionLoading = true;
            state.createPermissionData = false;
        },
        insertPermissionData (state, action) {

            state.isCreatePermissionLoading = false;
            state.permissionDataFetchCompleted = true;
            state.createPermissionData = true;
        },
        resetStatus (state, action) {
            state.isCreatePermissionLoading = false;
            state.permissionDataFetchCompleted = false;
            state.createPermissionData = false;
        },

    },
});

export default createPermissionDataSlice.reducer;

export function createPermissionDataStatus (roleName, permission) {
    return async (dispatch) => {
        dispatch(createPermissionDataSlice.actions.startLoading());
        try
        {
            const response = await axios.request({
                url: `roles/${ roleName }/permission`,
                data: { permission },
                method: "POST"
            })
            dispatch(createPermissionDataSlice.actions.insertPermissionData());
        } catch (error)
        {
            console.error(error);
        }
    }

}
export function resetPermissionDataStatus () {
    return async (dispatch) => {
        dispatch(createPermissionDataSlice.actions.resetStatus());
    }
}

