import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { LoadingButton } from '@mui/lab';
import DialogTitle from '@mui/material/DialogTitle';
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// @mui
import { Stack, Button, Typography, Box, TextareaAutosize, Grid, Alert, TextField } from '@mui/material';
// auth
import { useAuthContext } from '../../../auth/useAuthContext';
// locales
import { useLocales } from '../../../locales';
// routes
import { PATH_DOCS } from '../../../routes/paths';
import FormProvider, { RHFTextField, RHFEditor } from '../../../components/hook-form';

// ----------------------------------------------------------------------

export default function NavDocs () {
  const { user } = useAuthContext();

  const { translate } = useLocales();
  const [ open, setOpen ] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
  };


  const schema = yup.object().shape({
    subject: yup
      .string()

      .min(3, "Minimum 3 leters required").required("Subject is Required"),
    message: yup.string().min(10, "Minimum 10 letters required"),
  });
  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onBlur",
    defaultValues: {
      subject: "",
      message: "",
    },
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isValid }

  } = methods;

  const onSubmit = (data) => {
    if (isValid)
    {
      handleClickClose();
    }

  };
  return (
    <Stack
      spacing={3}
      sx={{
        px: 5,
        pb: 5,
        mt: 10,
        width: 1,
        display: 'block',
        textAlign: 'center',
      }}
    >
      <Box component="img" src="/assets/illustrations/illustration_docs.svg" />

      <div>
        <Typography gutterBottom variant="subtitle1">
          {`${ translate('docs.hi') }, ${ user?.displayName }`}
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary', whiteSpace: 'pre-line' }}>
          {`${ translate('docs.description') }`}
        </Typography>
      </div>

      <Button onClick={handleClickOpen} target="_blank" rel="noopener" variant="contained">
        {`${ translate('docs.documentation') }`}
      </Button>
      <Dialog open={open} onClose={handleClickClose} >
        <DialogTitle>Raise your ticket</DialogTitle>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>

            <Grid container spacing={2} my={1}>
              <Grid item xs={12}>
                <RHFTextField name="subject" title="Subject" label="Write your subject" />
              </Grid>
              <Grid item xs={12}>
                <RHFTextField name="message" title="Message" label="Write yout message" multiline
                  rows="10" />
              </Grid>
            </Grid>

          </DialogContent>
          <DialogActions>
            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              Save Changes
            </LoadingButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </Stack >
  );
}
