import axios from '../../utils/axios';


const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
    isPermissionLoading: false,
    permissions: [],
    permissionDataFetchCompleted: false,
};

const permissionDataSlice = createSlice({
    name: 'permissionData',
    initialState,
    reducers: {
        startLoading (state, action) {
            state.isPermissionLoading = true;
            state.permissions = [];
        },
        insertPermissionPage (state, action) {

            state.isPermissionLoading = false;
            state.permissionDataFetchCompleted = true;
            state.permissions = action.payload;
        },
        updatePermissionData (state, action) {
            state.isPermissionLoading = false;
            state.permissionDataFetchCompleted = true;
        },
    },
});

export default permissionDataSlice.reducer;

export function permissionDataItems (roleName) {
    return async (dispatch) => {

        dispatch(permissionDataSlice.actions.startLoading());
        try
        {
            const response = await axios.get(`roles/${ roleName }/permission`);
            dispatch(permissionDataSlice.actions.insertPermissionPage(response.data));
        } catch (error)
        {
            console.error(error);
        }
    }

}