import axios from '../../utils/axios';


const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
    isRoleLoading: false,
    roles: [],
    roleDataFetchCompleted: false,
};

const roleDataSlice = createSlice({
    name: 'roleData',
    initialState,
    reducers: {
        startLoading (state, action) {
            state.isRoleLoading = true;
            state.roles = [];
        },
        insertRolePage (state, action) {

            state.isRoleLoading = false;
            state.roleDataFetchCompleted = true;
            state.roles = action.payload;
        },
        updateRoleData (state, action) {
            state.isRoleLoading = false;
            state.roleDataFetchCompleted = true;
        },
    },
});

export default roleDataSlice.reducer;

export function roleDataItems () {
    return async (dispatch) => {

        dispatch(roleDataSlice.actions.startLoading());
        try
        {
            const response = await axios.get(`roles`);
            dispatch(roleDataSlice.actions.insertRolePage(response.data));
        } catch (error)
        {
            console.error(error);
        }
    }

}

