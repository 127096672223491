import axios from '../../utils/axios';


const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
    isResetPasswordLoading: false,
    resetPasswordData: false,
    ResetPasswordFetchCompleted: false,
};

const resetPasswordSlice = createSlice({
    name: 'resetUserPassword',
    initialState,
    reducers: {
        startLoading (state, action) {
            state.isResetPasswordLoading = true;
            state.resetPasswordData = false;
        },
        insertResetPassword (state, action) {

            state.isResetPasswordLoading = false;
            state.ResetPasswordFetchCompleted = true;
            state.resetPasswordData = true;
        },
        resetStatus (state, action) {

            state.isResetPasswordLoading = false;
            state.ResetPasswordFetchCompleted = false;
            state.resetPasswordData = false;
        },
        
        
    },
});

export default resetPasswordSlice.reducer;

export function userPasswordResetAction (username) {
    return async (dispatch) => {

        dispatch(resetPasswordSlice.actions.startLoading());
        try
        {
            const response = await axios.patch(`admin/user/${username}/resetpassword`);
            dispatch(resetPasswordSlice.actions.insertResetPassword());
        } catch (error)
        {
            console.error(error);
        }
    }

}

export function resetUserPasswordAction () {
    return async (dispatch) => {
        dispatch(resetPasswordSlice.actions.resetStatus());
    }
}
