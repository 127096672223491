import axios from '../../utils/axios';


const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
    isCardsLoading: false,
    cards: undefined,
    cardsFetchCompleted: false,
};

const cardsSlice = createSlice({
    name: 'cardsdata',
    initialState,
    reducers: {
        startLoading (state, action) {
            state.isCardsLoading = true;
            state.cards = undefined;
        },
        insertCards (state, action) {
           
            state.isCardsLoading = false;
            state.cardsFetchCompleted = true;
            state.cards = action.payload[0];
        },
        updateCards (state, action) {
            state.isCardsLoading = false;
            state.cardsFetchCompleted = true;
        },
    },
});

export default cardsSlice.reducer;

export function getCards () {
    return async (dispatch) => {

        dispatch(cardsSlice.actions.startLoading());
        try
        {
            const response = await axios.get(`gettotaljobs`);
            dispatch(cardsSlice.actions.insertCards(response.data));
        } catch (error)
        {
            console.error(error);
        }
    }

}

