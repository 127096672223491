import axios from '../../utils/axios';


const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
    isIndustryLoading: false,
    industry: [],
    industryFetchCompleted: false,
};

const industrySlice = createSlice({
    name: 'industrytype',
    initialState,
    reducers: {
        startLoading (state, action) {
            state.isIndustryLoading = true;
            state.industry = [];
        },
        insertIndustry (state, action) {

            state.isIndustryLoading = false;
            state.industryFetchCompleted = true;
            state.industry = action.payload;
        },
        updateIndustry (state, action) {
            state.isIndustryLoading = false;
            state.industryFetchCompleted = true;
        },
    },
});

export default industrySlice.reducer;

export function getIndustry () {
    return async (dispatch) => {

        dispatch(industrySlice.actions.startLoading());
        try
        {
            const response = await axios.get(`top5/industries`);
            dispatch(industrySlice.actions.insertIndustry(response.data));
        } catch (error)
        {
            console.error(error);
        }
    }

}

