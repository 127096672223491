import axios from '../../utils/axios';


const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
    isUpdateRoleLoading: false,
    updateRoleData: false,
    updateRoleDataFetchCompleted: false,
};

const updateRoleDataSlice = createSlice({
    name: 'updateRoleData',
    initialState,
    reducers: {
        startLoading (state, action) {
            state.isUpdateRoleLoading = true;
            state.updateRoleData = false;
        },
        insertRoleDataToUpdate (state, action) {

            state.isUpdateRoleLoading = false;
            state.updateRoleDataFetchCompleted = true;
            state.updateRoleData = true;
        },
        resetStatus (state, action) {
            state.isUpdateRoleLoading = false;
            state.updateRoleDataFetchCompleted = false;
            state.updateRoleData = false;
        },

    },
});

export default updateRoleDataSlice.reducer;

export function roleDataToBeUpdated () {
    return async (dispatch) => {
        dispatch(updateRoleDataSlice.actions.startLoading());
        try
        {

            const response = await axios.post(`roles/test1/permission`);
            dispatch(updateRoleDataSlice.actions.insertRoleDataToUpdate());
        } catch (error)
        {
            console.error(error);
        }
    }

}
export function resetRoleDatUpdateStatus () {
    return async (dispatch) => {
        dispatch(updateRoleDataSlice.actions.resetStatus());
    }
}

