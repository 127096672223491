
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices

import cardsReducer from './slices/cards';
import ChartReducer from './slices/chart';
import companyReducer from './slices/company';
import industryReducer from './slices/industry';
import companyPageReducer from './slices/companypage';
import userDataReducer from './slices/userprofile';
import EnableDataReducer from './slices/EnableUser';
import DisableDataReducer from './slices/DisableUser';
import ResetPasswordReducer from './slices/ResetPassword';
import RoleDataReducer from './slices/roleData';
import createUserReducer from './slices/createUser';
import editUserReducer from './slices/editUser';
import deleteDataReducer from './slices/deleteUser';
import createRoleDataReducer from './slices/createRole';
import deleteRoleDataReducer from './slices/deleteRole';
import editRoleDataReducer from './slices/editRole';
import updateRoleDataReducer from './slices/updateRole';
import createPermissionDataReducer from './slices/createPermission';
import permissionDataReducer from './slices/getPermission';
// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};


const rootReducer = combineReducers({
  cardsdata: cardsReducer,
  chartdata: ChartReducer,
  companytype: companyReducer,
  industrytype: industryReducer,
  companypage: companyPageReducer,
  userprofile: userDataReducer,
  enableUserStatus: EnableDataReducer,
  disableUserStatus: DisableDataReducer,
  resetUserPassword: ResetPasswordReducer,
  roleData: RoleDataReducer,
  createNewUser: createUserReducer,
  editUser: editUserReducer,
  deleteDataRows: deleteDataReducer,
  createRole: createRoleDataReducer,
  deleteRoleData: deleteRoleDataReducer,
  editRoleData: editRoleDataReducer,
  updateRoleData: updateRoleDataReducer,
  createPermission: createPermissionDataReducer,
  permissionData: permissionDataReducer,
});

export default rootReducer;
