import axios from '../../utils/axios';


const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
    isCreateUserLoading: false,
    userCreated: false,
    newUserDataFetchCompleted: false,
};

const createUserSlice = createSlice({
    name: 'createNewUser',
    initialState,
    reducers: {
        startLoading (state, action) {
            state.isCreateUserLoading = true;
            state.userCreated = false;
        },
        insertCreateUser (state, action) {

            state.isCreateUserLoading = false;
            state.newUserDataFetchCompleted = true;
            state.userCreated = true;
        },
        resetUser (state, action) {
            return initialState;
        }
    },
});

export default createUserSlice.reducer;

export function createNewUserAction ({ data }) {
    return async (dispatch) => {

        dispatch(createUserSlice.actions.startLoading());
        try
        {
            const response = await axios.request({ url: `admin/user`, method: "POST", data: { userName: data.username, userEmail: data.email, tempPassword: data.password, roleName: data.roles.toString(), name: data.name } });
            dispatch(createUserSlice.actions.insertCreateUser());
        } catch (error)
        {
            console.error(error);
        }
    }

}

export function resetNewUserAction () {
    return async (dispatch) => {
        dispatch(createUserSlice.actions.resetUser());
    }
}

