import axios from '../../utils/axios';


const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
    isCompanyLoading: false,
    company: [],
    companyFetchCompleted: false,
};

const companySlice = createSlice({
    name: 'companytype',
    initialState,
    reducers: {
        startLoading (state, action) {
            state.isCompanyLoading = true;
            state.company = [];
        },
        insertCompany (state, action) {

            state.isCompanyLoading = false;
            state.companyFetchCompleted = true;
            state.company = action.payload;
        },
        updateCompany (state, action) {
            state.isCompanyLoading = false;
            state.companyFetchCompleted = true;
        },
    },
});

export default companySlice.reducer;

export function getCompany () {
    return async (dispatch) => {

        dispatch(companySlice.actions.startLoading());
        try
        {
            const response = await axios.get(`top5/companyType`);
            dispatch(companySlice.actions.insertCompany(response.data));
        } catch (error)
        {
            console.error(error);
        }
    }

}

