import axios from '../../utils/axios';


const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
    isEnableUserLoading: false,
    enableUserFetchCompleted: false,
    isUserEnabled: false
};

const enableUserSlice = createSlice({
    name: 'enableUserStatus',
    initialState,
    reducers: {
        startLoading (state, action) {
            state.isEnableUserLoading = true;
            state.enableUserFetchCompleted = false;
            state.isUserEnabled = false;
        },
        insertEnableUser (state, action) {

            state.isEnableUserLoading = false;
            state.enableUserFetchCompleted = true;
            state.isUserEnabled = true;

        },
        userEnableStatusFailed (state, action) {

            state.isEnableUserLoading = false;
            state.enableUserFetchCompleted = true;
            state.isUserEnabled = false;

        },
        resetStatus (state, action) {
            state.isEnableUserLoading = false;
            state.enableUserFetchCompleted = false;
            state.isUserEnabled = false;
        },
    },
});

export default enableUserSlice.reducer;

export function enableUserStatusAction (username) {
    return async (dispatch) => {

        dispatch(enableUserSlice.actions.startLoading());
        try
        {
            const response = await axios.patch(`admin/user/${ username }/enable`);
            dispatch(enableUserSlice.actions.insertEnableUser());
        } catch (error)
        {
            dispatch(enableUserSlice.actions.userEnableStatusFailed());
        }
    }

}

export function resetUserEnableStatus () {
    return async (dispatch) => {
        dispatch(enableUserSlice.actions.resetStatus());
    }
}

