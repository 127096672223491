import axios from '../../utils/axios';


const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
    isUserDataLoading: false,
    userdata: [],
    userDataFetchCompleted: false,
};

const userDataSlice = createSlice({
    name: 'userprofile',
    initialState,
    reducers: {
        startLoading (state, action) {
            state.isUserDataLoading = true;
            state.userdata = undefined;
        },
        insertUserData (state, action) {

            state.isUserDataLoading = false;
            state.userDataFetchCompleted = true;
            state.userdata = action.payload;
        },
        updateUserData (state, action) {
            state.isUserDataLoading = false;
            state.userDataFetchCompleted = true;
        },
    },
});

export default userDataSlice.reducer;

export function getUserData () {
    return async (dispatch) => {
        console.log('hello')
        dispatch(userDataSlice.actions.startLoading());
        try
        {
            const response = await axios.get(`users`);
            dispatch(userDataSlice.actions.insertUserData(response.data));
        } catch (error)
        {
            console.error(error);
        }
    }

}

